import React, { useState, useEffect } from 'react';
import LazyLoad from 'react-lazyload';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import './LiveNews.css';

const LiveNews = () => {
  const channels = [
    { name: 'Channel 1', url: 'https://www.youtube.com/embed/II_m28Bm-iM?si=0DNKg_vKg1RoNUnn', img: 'https://res.cloudinary.com/dtke9rvb6/image/upload/v1720716903/kovvur-icons/livetv/5_11zon_r1lsxb.jpg', altText: "News" },
    { name: 'Channel 2', url: 'https://www.youtube.com/embed/L0RktSIM980?si=jdT7rIkdhkt7Ag8t', img: 'https://res.cloudinary.com/dtke9rvb6/image/upload/v1720716730/kovvur-icons/livetv/HMTV_2__2_11zon_vhqdhk.jpg', altText: "News" },
    { name: 'Channel 3', url: 'https://www.youtube.com/embed/HoYsWagMFfE?si=ixp9FYYjkGv16dLh', img: 'https://res.cloudinary.com/dtke9rvb6/image/upload/v1720716903/kovvur-icons/livetv/4_11zon_wxb8mk.jpg', altText: "News" },
    { name: 'Channel 4', url: 'https://www.youtube.com/embed/ho9JlBnrGWg?si=VX0ei8cew-PjL9t6', img: 'https://res.cloudinary.com/dtke9rvb6/image/upload/v1720716903/kovvur-icons/livetv/2_11zon_kxkhzi.jpg', altText: "News" },
    { name: 'Channel 5', url: 'https://www.youtube.com/embed/9kCcUipLPJM?si=kz-bx-H58E8ZVo_i', img: 'https://res.cloudinary.com/dtke9rvb6/image/upload/v1720716730/kovvur-icons/livetv/sakshi_6_11zon_lleyvl.jpg', altText: "News" },
    { name: 'Channel 6', url: 'https://www.youtube.com/embed/3JodH4xoqJs?si=-v0qqNM8zHkUtIcS', img: 'https://res.cloudinary.com/dtke9rvb6/image/upload/v1720716730/kovvur-icons/livetv/HMTV_3_11zon_ahk4nf.jpg', altText: "News" },
    { name: 'Channel 7', url: 'https://www.youtube.com/embed/ffI9-IioJK8?si=jJ552XHhYrYoT4kw', img: 'https://res.cloudinary.com/dtke9rvb6/image/upload/v1720716730/kovvur-icons/livetv/Prime9_2__4_11zon_zyshkw.jpg', altText: "News" },
    { name: 'Channel 8', url: 'https://www.youtube.com/embed/4PpS_RGUzfQ?si=8FWCc1STOrU0kTBT', img: 'https://res.cloudinary.com/dtke9rvb6/image/upload/v1720716730/kovvur-icons/livetv/Prime9_5_11zon_bbcy0w.jpg', altText: "News" },
    { name: 'Channel 9', url: 'https://www.youtube.com/embed/byG7EGw9NPs?si=iFRTN3BiPf22ufox', img: 'https://res.cloudinary.com/dtke9rvb6/image/upload/v1720717798/kovvur-icons/livetv/Prime9_11zon_yucpf9.jpg', altText: "News" },
    { name: 'Channel 10', url: 'https://www.youtube.com/embed/_xtceKvlVYQ?si=uh7HMZafOn2IdNpC', img: 'https://res.cloudinary.com/dtke9rvb6/image/upload/v1720716730/kovvur-icons/livetv/3_1_11zon_nm8cpi.jpg', altText: "News" },
  ];

  const [selectedChannel, setSelectedChannel] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate a delay to showcase the loading skeleton
    const timer = setTimeout(() => {
      setSelectedChannel(channels[0]);
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  const handleChannelClick = (channel) => {
    setSelectedChannel(channel);
  };

  return (
    <div className="live-news-container">
      <h2 className="live-channel-title">Live News</h2>
      <p className="live-channel-para">Get Live channels Now!</p>
      <div className="channels-list">
        {channels.map((channel, index) => (
          <div
            key={index}
            className={`channel-item ${selectedChannel?.name === channel.name ? 'selected' : ''}`}
            onClick={() => handleChannelClick(channel)}
          >
            <LazyLoad height={75} offset={100} once>
              {loading ? (
                <Skeleton height={75} width={75} />
              ) : (
                <img src={channel.img} alt={channel.name} className="channel-img" />
              )}
            </LazyLoad>
          </div>
        ))}
      </div>
      <div className="video-container">
        {loading ? (
          <Skeleton height={250} width="100%" />
        ) : (
          <iframe
            src={selectedChannel?.url}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
            className="Youtube-frame" 
          ></iframe>
        )}
      </div>
    </div>
  );
};

export default LiveNews;
