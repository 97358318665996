import React, { useState } from "react";
import { FaBarsStaggered, FaChevronDown, FaChevronUp } from "react-icons/fa6";
import { FaInstagram, FaFacebook, FaLinkedin, FaYoutube } from 'react-icons/fa';
import { FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";
import "./Navbar.css";
import { FaThreads } from "react-icons/fa6";

const Navbar = () => {
  const [clicked, setClicked] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);

  const handleClick = () => {
    setClicked(!clicked);
  };

  const handleDropdown = (dropdownName) => {
    if (openDropdown === dropdownName) {
      setOpenDropdown(null); // Close the dropdown if it's already open
    } else {
      setOpenDropdown(dropdownName); // Open the clicked dropdown
    }
  };

  return (
    <>
      <nav>
        <Link to="/">
          <img
            src="https://res.cloudinary.com/dtke9rvb6/image/upload/v1719463044/kovvur-icons/travels-icons/kovvur-mana-logo.png"
            className="logo"
            alt="logo"
          />
        </Link>

        <div id="mobile" onClick={handleClick}>
          <FaBarsStaggered color="#fff" size={24} />
        </div>

        <div className={clicked ? "menu active" : "menu"}>
          <div className="menu-header">
            <span className="user-greeting">Hello User</span>
            <div className="close-icon" onClick={handleClick}>
              <FaTimes color="#fff" size={24} />
            </div>
          </div>
          <ul id="navbar">
            <li>
              <Link className="active nav-active" to="/">
                Our Services
              </Link>
            </li>
            <li className={`dropdown ${openDropdown === 'travel' ? "open" : ""}`}>
              <span onClick={() => handleDropdown('travel')}>
                Travel Services{" "}
                {openDropdown === 'travel' ? (
                  <FaChevronUp />
                ) : (
                  <FaChevronDown />
                )}
              </span>
              <ul className="sub-menu">
                <li className="sub-menu-text">
                  <Link to="/autosservice">Auto Services</Link>
                </li>
                <li className="sub-menu-text">
                  <Link to="/carservice">Car Services</Link>
                </li>
                <li className="sub-menu-text">
                  <Link to="/vanservice">Van Services</Link>
                </li>
                <li className="sub-menu-text">
                  <Link to="/wheelloadersservice">Wheel Loaders</Link>
                </li>
                <li className="sub-menu-text">
                  <Link to="/lorryservice">Lorry Services</Link>
                </li>
                <li className="sub-menu-text">
                  <Link to="/busservice">Bus Services</Link>
                </li>
              </ul>
            </li>


            <li className={`dropdown ${openDropdown === 'Health' ? "open" : ""}`}>
              <span onClick={() => handleDropdown('Health')}>
                Health Services{" "}
                {openDropdown === 'Health' ? (
                  <FaChevronUp />
                ) : (
                  <FaChevronDown />
                )}
              </span>
              <ul className="sub-menu">
                <li className="sub-menu-text">
                  <Link to="/healthrmplist">Health RMP</Link>
                </li>
                <li className="sub-menu-text">
                  <Link to="/hospitals">Hospitals</Link>
                </li>
                <li className="sub-menu-text">
                  <Link to="/dentalhospital">Dental Hospitals</Link>
                </li>
                <li className="sub-menu-text">
                  <Link to="/ambulance">Ambulance</Link>
                </li>
                <li className="sub-menu-text">
                  <Link to="/bloodtest">Blood Test</Link>
                </li>
                <li className="sub-menu-text">
                  <Link to="/homeopathy">Homeopathy</Link>
                </li>
              </ul>
            </li>
            {/* here */}
            <li className={`dropdown ${openDropdown === 'workers' ? "open" : ""}`}>
              <span onClick={() => handleDropdown('workers')}>
                Workers Services{" "}
                {openDropdown === 'workers' ? (
                  <FaChevronUp />
                ) : (
                  <FaChevronDown />
                )}
              </span>
              <ul className="sub-menu">
                <li className="sub-menu-text">
                  <Link to="/acrepairs">AC Repairs</Link>
                </li>
                <li className="sub-menu-text">
                  <Link to="/ceiling">Ceiling</Link>
                </li>
                <li className="sub-menu-text">
                  <Link to="/contructionworkers">Construction Workers</Link>
                </li>
                <li className="sub-menu-text">
                  <Link to="/electrition">Electrician</Link>
                </li>
                <li className="sub-menu-text">
                  <Link to="/plumber">Plumber</Link>
                </li>
                <li className="sub-menu-text">
                  <Link to="/tailswork">Tiles Work</Link>
                </li>
              </ul>
            </li>

            <li className={`dropdown ${openDropdown === 'Events' ? "open" : ""}`}>
              <span onClick={() => handleDropdown('Events')}>
                Events Services{" "}
                {openDropdown === 'Events' ? (
                  <FaChevronUp />
                ) : (
                  <FaChevronDown />
                )}
              </span>
              <ul className="sub-menu">
                <li className="sub-menu-text">
                  <Link to="/eventplanner">Event Planner</Link>
                </li>
                <li className="sub-menu-text">
                  <Link to="/eventdecro">Event Decoration</Link>
                </li>
                <li className="sub-menu-text">
                  <Link to="/eventtents">Event Tents</Link>
                </li>
                <li className="sub-menu-text">
                  <Link to="/foodcaterings">Food Caterings</Link>
                </li>
                <li className="sub-menu-text">
                  <Link to="/functionhalls">Function Halls</Link>
                </li>
                <li className="sub-menu-text">
                  <Link to="/lightdecro">Light Decoration</Link>
                </li>
              </ul>
            </li>
            {/* here */}

            {/* li-here */}
            {/* <li>
              <Link to="/other">Other Services</Link>
            </li> */}
            <li className={`dropdown ${openDropdown === 'Other Services' ? "open" : ""}`}>
              <span onClick={() => handleDropdown('Other Services')}>
                Other Services{" "}
                {openDropdown === 'Other Services' ? (
                  <FaChevronUp />
                ) : (
                  <FaChevronDown />
                )}
              </span>
              <ul className="sub-menu">
                <li className="sub-menu-text">
                  <Link to="/beautyparlorlist">Beauty Parlors</Link>
                </li>
                <li className="sub-menu-text">
                  <Link to="/milkdairylist">Milk Dairy</Link>
                </li>
                <li className="sub-menu-text">
                  <Link to="/photography">Photography</Link>
                </li>
                <li className="sub-menu-text">
                  <Link to="/petshoplist">Pet Shops</Link>
                </li>
                <li className="sub-menu-text">
                  <Link to="/archakululist">Archakulu</Link>
                </li>
                <li className="sub-menu-text">
                  <Link to="/cccameralist">CCTV Camera</Link>
                </li>
                <li className="sub-menu-text">
                  <Link to="/drivingschoollist">Driving Schools</Link>
                </li>
                <li className="sub-menu-text">
                  <Link to="/housewarming">House Warming</Link>
                </li>
                <li className="sub-menu-text">
                  <Link to="/interiorlist">Interior</Link>
                </li>
                <li className="sub-menu-text">
                  <Link to="/internetproviderlist">Internet Providers</Link>
                </li>
                <li className="sub-menu-text">
                  <Link to="/tailoringlist">Tailoring</Link>
                </li>
                <li className="sub-menu-text">
                  <Link to="/tuitionslist">Tuitions</Link>
                </li>
              </ul>
            </li>


            {/* li-here */}
            {/* <h5>Explore Mana Kovvur</h5> */}
            <li>
              <Link className="active nav-active" to="/sitemap">
                Sitemap
              </Link>
            </li>

            <li>
              <Link className="active nav-active" to="/termsandconditions">
                Terms & Conditions
              </Link>
            </li>

            <li>
              <Link className="active nav-active" to="/privacy">
                Privacy Policy
              </Link>
            </li>
          </ul>
          <div className="info" data-aos="fade-up">
            <h1 className="social-title">Social Media</h1>
            <div className="sociallogos">
              <div className="logobox">
                <a href="https://www.instagram.com/mana_kovvur_official/" target="_blank" rel="noopener noreferrer" className="social-link"><FaInstagram /></a>
                
                <a href="https://www.facebook.com/profile.php?id=61563746487029" target="_blank" rel="noopener noreferrer" className="social-link"><FaFacebook /></a>
                <a href="https://www.threads.net/@mana_kovvur_official" target="_blank" rel="noopener noreferrer" className="social-link"><FaThreads /></a>
                <a href="#" className="social-link"><FaYoutube /></a>
              </div>
            </div>
          </div>
        </div>
      </nav>


    </>
  );
};

export default Navbar;
