import React from 'react';
// import { useNavigate } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import './../../../Stylings/MainCss/Style.css';

const Vehicles = () => {
    
    return (
        <div>
            <div className="hello-header">
            <Link to="/">
                <button className="hello-back-button" >
                    <FaArrowLeft />
                </button>
                </Link>

                <h1>TRAVELS</h1>

            </div>
            <main className="hello-main-content">
                <section className="hello-travels-container">
                    <div className="hello-travels">
                        <section className="hello-cards">
                        <Link to="/autosservice" className="hello-card-link">
                            <div className="hello-card-wrapper">
                                
                                <div className="hello-card">
                                    <img src="https://res.cloudinary.com/dtke9rvb6/image/upload/v1719575319/kovvur-icons/travels-icons/4_cmjsvh.jpg" alt="auto" />
                                </div>
                                <div className="hello-card-info">
                                    <h3>Auto</h3>
                                </div>
                            </div>
                            </Link>
                            <Link to="/carservice" className="hello-card-link">
                            <div className="hello-card-wrapper">
                                <div className="hello-card">

                                    <img src="https://res.cloudinary.com/dtke9rvb6/image/upload/v1719575318/kovvur-icons/travels-icons/5_cvrw8p.jpg" alt="car" />
                                </div>
                                <div className="hello-card-info">
                                    <h3>Car</h3>
                                </div>
                            </div>
                            </Link>
                            <Link to="/busservice" className="hello-card-link">
                            <div className="hello-card-wrapper">
                                <div className="hello-card">
                                    <img src="https://res.cloudinary.com/dtke9rvb6/image/upload/v1719577421/kovvur-icons/travels-icons/Untitled_design_1__11zon_bathsg.jpg" alt="bus" />
                                </div>
                                <div className="hello-card-info">
                                    <h3>Bus</h3>
                                </div>
                            </div>
                            </Link>
                            <Link to="/vanservice" className="hello-card-link">
                            <div className="hello-card-wrapper">
                                <div className="hello-card">
                                    <img src="https://res.cloudinary.com/dtke9rvb6/image/upload/v1719575318/kovvur-icons/travels-icons/2_p8gyuj.jpg" alt="van" />
                                </div>
                                <div className="hello-card-info">
                                    <h3>Van</h3>
                                </div>
                            </div>
                            </Link>
                            <Link to="/wheelloadersservice" className="hello-card-link">
                            <div className="hello-card-wrapper">
                                <div className="hello-card">
                                    <img src="https://res.cloudinary.com/dtke9rvb6/image/upload/v1719575318/kovvur-icons/travels-icons/6_sgjtyt.jpg" alt="wheel loaders" />
                                </div>
                                <div className="hello-card-info">
                                    <h3>Wheel <br />Loaders</h3>
                                </div>
                            </div>
                            </Link>
                            <Link to="/lorryservice" className="hello-card-link">
                            <div className="hello-card-wrapper">
                                <div className="hello-card">
                                    <img src="https://res.cloudinary.com/dtke9rvb6/image/upload/v1719575319/kovvur-icons/travels-icons/7_zcbuyt.jpg" alt="lorry" />
                                </div>
                                <div className="hello-card-info">
                                    <h3>Lorry</h3>
                                </div>
                            </div>
                            </Link>

                        </section>
                    </div>
                </section>
            </main>
        </div>
    );
}

export default Vehicles;
