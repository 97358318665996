import React, { useState, useEffect, useRef } from 'react'; 
import { fetchVideos } from './mockApi'; // Ensure this file exists with your updated fetchVideos function
import './YouTubeVideos.css';

const YouTubeVideos = () => {
  const [videos, setVideos] = useState([]);
  const [playing, setPlaying] = useState(null); // Track the currently playing video
  const containerRef = useRef(null);
  const playerRefs = useRef([]); // Reference for all player instances
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  useEffect(() => {
    const getVideos = async () => {
      try {
        const videoData = await fetchVideos();
        setVideos(videoData);
      } catch (error) {
        console.error('Failed to fetch videos', error);
      }
    };

    getVideos();
  }, []);

  // Handle mouse drag for scrolling
  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - containerRef.current.offsetLeft);
    setScrollLeft(containerRef.current.scrollLeft);
  };

  const handleMouseLeave = () => {
    setIsDragging(false);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (e) => {
    if (!isDragging || !containerRef.current) return;
    const x = e.pageX - containerRef.current.offsetLeft;
    const walk = (x - startX) * 2; // Speed of scroll
    containerRef.current.scrollLeft = scrollLeft - walk;
  };

  const onPlayerStateChange = (event, index) => {
    if (event.data === window.YT.PlayerState.PAUSED || event.data === window.YT.PlayerState.ENDED) {
      // If the video is paused or ended, reset playing state
      setPlaying(null);
    } else if (event.data === window.YT.PlayerState.PLAYING) {
      // Pause other videos if this one starts playing
      playerRefs.current.forEach((player, idx) => {
        if (idx !== index && player && player.getPlayerState() === window.YT.PlayerState.PLAYING) {
          player.pauseVideo(); // Pause the previous video
        }
      });
      setPlaying(index); // Set the current playing video
    }
  };

  const handleVideoClick = (index) => {
    if (playerRefs.current[playing] && playing !== index) {
      // Pause the currently playing video if a new one is clicked
      playerRefs.current[playing].pauseVideo();
      playerRefs.current[playing].seekTo(0); // Reset the previous video to start from the beginning
    }

    setPlaying(index); // Update playing state to the new video

    // Trigger play for the clicked video
    if (playerRefs.current[index]) {
      playerRefs.current[index].playVideo();
    }
  };

  useEffect(() => {
    // Load YouTube iframe API
    if (!window.YT) {
      const tag = document.createElement('script');
      tag.src = "https://www.youtube.com/iframe_api";
      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    }
  }, []);

  const onReady = (event, index) => {
    // Store each player instance in the ref array
    playerRefs.current[index] = event.target;
  };

  return (
    <div>
      <h2 className="heading">Trending Videos</h2>
      
      <div 
        className="youtubecontainer" 
        ref={containerRef}
        onMouseDown={handleMouseDown}
        onMouseLeave={handleMouseLeave}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
      >
        <div className="youtubers">
          {videos.map((video, index) => (
            <div
              key={video.id}
              className={`youtubers__row ${playing === index ? 'playing' : ''}`}
              onClick={() => handleVideoClick(index)}
            >
              <div className="videoWrapper">
                {playing !== index && (
                  <div
                    className="video-preview-image"
                    style={{
                      backgroundImage: `url(${video.previewImage})`,
                    }}
                  ></div>
                )}

                <iframe
                  id={`player-${index}`}
                  width="100%"
                  height="100%"
                  src={`https://www.youtube.com/embed/${video.id}?enablejsapi=1`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                  onLoad={() => {
                    const player = new window.YT.Player(`player-${index}`, {
                      events: {
                        'onReady': (event) => onReady(event, index),
                        'onStateChange': (event) => onPlayerStateChange(event, index),
                      }
                    });
                  }}
                ></iframe>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default YouTubeVideos;
