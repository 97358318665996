import React from 'react';
import './Jobupdates.css';
import { Link } from 'react-router-dom';

function JobCard() {
  return (
    <div className="job-card">
      <div className="job-card__img">
        <h1 className='h1text'>JOB UPDATES</h1>
        <p className='jobtext'>Stay Updated with the Latest Government <br />and Private Job Alerts Daily!</p>
      </div>
      <div className="job-card__img-wrapper">
        <img src="https://res.cloudinary.com/dtke9rvb6/image/upload/v1720076358/kovvur-icons/elementskovvur/jobsupdate.jpg" alt="Avatar" className="job-card__img-avatar" />
      </div>
      <div className="job-card__action">
        <Link to="/jobspage">
          <button className='job-button'>
            Latest Jobs!
          </button>
        </Link>
      </div>
    </div>
  );
}

export default JobCard;
