// GovCard.jsx
import React from 'react';

const PrejobCard = ({ imgSrc, title, description, pdfLink, applyLink }) => {
    return (
        <div className="gov-card">
            <div className="gov-icon">
                <img src={imgSrc} alt="Job Logo" />
            </div>
            <div className="gov-content">
                <span className="gov-title">{title}</span>
                <div className="gov-desc">{description}</div>
                <div className="gov-actions">
                    {/* <div>
                        <a href={pdfLink} className="gov-download">View Pdf</a>
                    </div> */}
                    <div>
                        <a href={applyLink} target="_blank"  rel="noreferrer" className="private-notnow">Apply Now</a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PrejobCard;
