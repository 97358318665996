import React, { useState, useEffect, useCallback } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "./Ads.css";

// Carousel component
const AdsCustomCarousel = ({ children }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [slideDone, setSlideDone] = useState(true);
  const [timeID, setTimeID] = useState(null);
  const [touchStartX, setTouchStartX] = useState(0);
  const [touchEndX, setTouchEndX] = useState(0);

  const slideNext = useCallback(() => {
    setActiveIndex((val) =>
      val >= React.Children.count(children) - 1 ? 0 : val + 1
    );
  }, [children]);

  const slidePrev = useCallback(() => {
    setActiveIndex((val) =>
      val <= 0 ? React.Children.count(children) - 1 : val - 1
    );
  }, [children]);

  useEffect(() => {
    if (slideDone) {
      setSlideDone(false);
      setTimeID(
        setTimeout(() => {
          slideNext();
          setSlideDone(true);
        }, 4000)
      );
    }
  }, [slideDone, slideNext]);

  const handleTouchStart = (e) => {
    setTouchStartX(e.changedTouches[0].screenX);
  };

  const handleTouchMove = (e) => {
    setTouchEndX(e.changedTouches[0].screenX);
  };

  const handleTouchEnd = () => {
    if (touchStartX - touchEndX > 50) {
      slideNext();
    }

    if (touchEndX - touchStartX > 50) {
      slidePrev();
    }
  };

  const AutoPlayStop = () => {
    if (timeID) {
      clearTimeout(timeID);
      setSlideDone(false);
    }
  };

  const AutoPlayStart = () => {
    if (!slideDone) {
      setSlideDone(true);
    }
  };

  return (
    <div
      className="ads-container__slider"
      onMouseEnter={AutoPlayStop}
      onMouseLeave={AutoPlayStart}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      {React.Children.map(children, (item, index) => (
        <div
          className={`ads-slider__item ads-slider__item-active-${
            activeIndex + 1
          }`}
          key={index}
        >
          {item}
        </div>
      ))}

      <div className="ads-container__slider__links">
        {React.Children.map(children, (item, index) => (
          <button
            key={index}
            className={
              activeIndex === index
                ? "ads-container__slider__links-small ads-container__slider__links-small-active"
                : "ads-container__slider__links-small"
            }
            onClick={(e) => {
              e.preventDefault();
              setActiveIndex(index);
            }}
          ></button>
        ))}
      </div>

      <button
        className="ads-slider__btn-next"
        onClick={(e) => {
          e.preventDefault();
          slideNext();
        }}
      >
        {""}
      </button>
      <button
        className="ads-slider__btn-prev"
        onClick={(e) => {
          e.preventDefault();
          slidePrev();
        }}
      >
        {""}
      </button>
    </div>
  );
};

// Main component
const AdsCarouselMain = () => {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://raw.githubusercontent.com/manakovvur/ads-one/main/ads.json"
        );
        const data = await response.json();
        setImages(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching images:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="ads-carousel-container">
      <AdsCustomCarousel>
        {loading ? (
          <div className="skeleton-loader">
            <Skeleton height={200} width={300} count={3} />
          </div>
        ) : (
          images.map((image, index) => (
            <img key={index} src={image.imgURL} alt={image.imgAlt} />
          ))
        )}
      </AdsCustomCarousel>
    </div>
  );
};

export default AdsCarouselMain;
