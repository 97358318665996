import React from 'react';
// import { useNavigate } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import './../../Stylings/MainCss/ListStyle.css'

const JobPage = () => {

    return (
        <div>
            <div className="hello-header">
                <Link to="/">
                    <button className="hello-back-button" >
                        <FaArrowLeft />
                    </button>
                </Link>

                <h1>Latest Jobs</h1>

            </div>
            <main className="hello-main-content">
                <section className="hello-travels-container">
                    <div className="hello-travels">
                        <section className="hello-cards">
                            <Link to="/govlist" className="hello-card-link">
                                <div className="hello-card-wrapper">

                                    <div className="hello-card">
                                        <img src="https://res.cloudinary.com/dtke9rvb6/image/upload/v1721197349/kovvur-icons/jobs/Ordr_11zon_jxohky.jpg" alt="auto" />
                                    </div>
                                    <div className="hello-card-info">
                                        <h3>Government Jobs</h3>
                                    </div>
                                </div>
                            </Link>
                            <Link to="/prejoblist" className="hello-card-link">
                            <div className="hello-card-wrapper">

                                <div className="hello-card">
                                    <img src="https://res.cloudinary.com/dtke9rvb6/image/upload/v1721197350/kovvur-icons/jobs/Ordr_1__11zon_h9utfw.jpg" alt="car" />
                                </div>
                                <div className="hello-card-info">
                                    <h3>Private Jobs </h3>
                                </div>
                            </div>
                            </Link>

                        </section>
                    </div>
                </section>
            </main>
        </div>
    );
}

export default JobPage;
