import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import './Service.css';

const ServiceCard = ({ link, imageSrc, altText, title }) => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const img = new Image();
        img.src = imageSrc;
        img.onload = () => setIsLoading(false);
        return () => {
            // Cleanup function to avoid memory leaks
            img.onload = null;
        };
    }, [imageSrc]);

    return (
        <div className="card-wrapper">
            <Link to={link}>
                <div className={`service-item ${isLoading ? 'loading' : ''}`}>
                    {isLoading ? (
                        <Skeleton height={100} width={100} />
                    ) : (
                        <img src={imageSrc} alt={altText} className="mainicon" />
                    )}
                </div>
                <div className="card-info">
                    <h3>{title}</h3>
                </div>
            </Link>
        </div>
    );
};

const Services = () => {
    const services = [
        { link: "/vehicles", imageSrc: "https://res.cloudinary.com/dtke9rvb6/image/upload/v1722504757/kovvur-icons/Service-Icons/jpeg-optimizer_travels_mszee5.jpg", altText: "Travels", title: "Travels" },
        { link: "/Events", imageSrc: "https://res.cloudinary.com/dtke9rvb6/image/upload/v1722504749/kovvur-icons/Service-Icons/jpeg-optimizer_events_c1vy2p.jpg", altText: "Events", title: "Events" },
        { link: "/photography", imageSrc: "https://res.cloudinary.com/dtke9rvb6/image/upload/v1722504753/kovvur-icons/Service-Icons/jpeg-optimizer_photography_pb9a3y.jpg", altText: "Photography", title: "Photography" },
        { link: "/Health", imageSrc: "https://res.cloudinary.com/dtke9rvb6/image/upload/v1722504749/kovvur-icons/Service-Icons/jpeg-optimizer_health_ar62fr.jpg", altText: "Health", title: "Health" },
        { link: "/Mechanicslist", imageSrc: "https://res.cloudinary.com/dtke9rvb6/image/upload/v1726392387/kovvur-icons/mechanics.jpg", altText: "Mechanics", title: "Mechanics" },
        
        { link: "/MilkdairyList", imageSrc: "https://res.cloudinary.com/dtke9rvb6/image/upload/v1722504751/kovvur-icons/Service-Icons/jpeg-optimizer_milk_gco3e9.jpg", altText: "Milk dairy", title: "Milk dairy" },
        { link: "/DrivingSchoolList", imageSrc: "https://res.cloudinary.com/dtke9rvb6/image/upload/v1722504749/kovvur-icons/Service-Icons/jpeg-optimizer_driving_scl_xhiltk.jpg", altText: "Driving Schools", title: "Driving Schools" },
        { link: "/CCcameraList", imageSrc: "https://res.cloudinary.com/dtke9rvb6/image/upload/v1722504749/kovvur-icons/Service-Icons/jpeg-optimizer_cc_camera_qkmb7p.jpg", altText: "CC Camera", title: "CC Camera" },
        { link: "/InternetProviderList", imageSrc: "https://res.cloudinary.com/dtke9rvb6/image/upload/v1722504750/kovvur-icons/Service-Icons/jpeg-optimizer_internet_provider_px9duk.jpg", altText: "Internet Providers", title: <>Internet <br /> providers</> },
        { link: "/BeautyParlorList", imageSrc: "https://res.cloudinary.com/dtke9rvb6/image/upload/v1722504748/kovvur-icons/Service-Icons/jpeg-optimizer_beauty_hgxrrk.jpg", altText: "Beauty Parlour", title: <>Beauty <br />Parlour </> },
        { link: "/Workers", imageSrc: "https://res.cloudinary.com/dtke9rvb6/image/upload/v1722504757/kovvur-icons/Service-Icons/jpeg-optimizer_workers_v5vdfj.jpg", altText: "Workers", title: "Workers" },
        { link: "/TuitionsList", imageSrc: "https://res.cloudinary.com/dtke9rvb6/image/upload/v1722504757/kovvur-icons/Service-Icons/jpeg-optimizer_tuitions_hqzxf3.jpg", altText: "Tuitions", title: "Tuitions" },
        { link: "/InteriorList", imageSrc: "https://res.cloudinary.com/dtke9rvb6/image/upload/v1722504749/kovvur-icons/Service-Icons/jpeg-optimizer_Interior_vhfoki.jpg", altText: "Interior", title: "Interior" },
        { link: "/Weldingworkslist", imageSrc: "https://res.cloudinary.com/dtke9rvb6/image/upload/v1726392387/kovvur-icons/welding.jpg", altText: "Welding", title: "Welding" },

        { link: "/TailoringList", imageSrc: "https://res.cloudinary.com/dtke9rvb6/image/upload/v1722504754/kovvur-icons/Service-Icons/jpeg-optimizer_tailoring_dvnmhz.jpg", altText: "Tailoring",  title: <>Boutiques/<br />Tailoring </> },
        { link: "/ArchakuluList", imageSrc: "https://res.cloudinary.com/dtke9rvb6/image/upload/v1722504754/kovvur-icons/Service-Icons/jpeg-optimizer_priest_wf2yie.jpg", altText: "Archakulu", title: "Archakulu" },
        { link: "/Accountant", imageSrc: "https://res.cloudinary.com/dtke9rvb6/image/upload/v1725270864/kovvur-icons/Accountent.jpg", altText: "GST", title: <>Accountant <br />GST Services </> },
        { link: "/PetshopList", imageSrc: "https://res.cloudinary.com/dtke9rvb6/image/upload/v1722504753/kovvur-icons/Service-Icons/jpeg-optimizer_pet_shop_vdapse.jpg", altText: "Pet shop", title: "Pet shop" },
    ];

    return (
        <div className="services-container">
            <h2>Services</h2>
            <div className="services">
                {services.map(service => (
                    <ServiceCard
                        key={service.title}
                        link={service.link}
                        imageSrc={service.imageSrc}
                        altText={service.altText}
                        title={service.title}
                    />
                ))}
            </div>
        </div>
    );
};

export default Services;
