import React from 'react';
// import { useNavigate } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import './../../../Stylings/MainCss/Style.css';

const Workers = () => {
    
    return (
        <div>
            <div className="hello-header">
            <Link to="/">
                <button className="hello-back-button" >
                    <FaArrowLeft />
                </button>
                </Link>

                <h1>WORKERS</h1>

            </div>
            <main className="hello-main-content">
                <section className="hello-travels-container">
                    <div className="hello-travels">
                        <section className="hello-cards">
                        <Link to="/acrepairs" className="hello-card-link">
                            <div className="hello-card-wrapper">
                                
                                <div className="hello-card">
                                    <img src="https://res.cloudinary.com/dtke9rvb6/image/upload/v1720946830/kovvur-icons/workers/AC_Repairs.jpg" alt="ACRepairs" />
                                </div>
                                <div className="hello-card-info">
                                    <h3>AC Repairs</h3>
                                </div>
                            </div>
                            </Link>
                            <Link to="/ceiling" className="hello-card-link">
                            <div className="hello-card-wrapper">
                                <div className="hello-card">
                                    <img src="https://res.cloudinary.com/dtke9rvb6/image/upload/v1720946830/kovvur-icons/workers/Ceiling_work.jpg" alt="CeilingWork" />
                                </div>
                                <div className="hello-card-info">
                                    <h3>Ceiling Works </h3>
                                </div>
                            </div>
                            </Link>
                            <Link to="/contructionworkers" className="hello-card-link">
                            <div className="hello-card-wrapper">
                                <div className="hello-card">
                                    <img src="https://res.cloudinary.com/dtke9rvb6/image/upload/v1720946830/kovvur-icons/workers/Mestri.jpg" alt="Mestri" />
                                </div>
                                <div className="hello-card-info">
                                    <h3>Mestri</h3>
                                </div>
                            </div>
                            </Link>
                            <Link to="/electrition" className="hello-card-link">
                            <div className="hello-card-wrapper">
                                <div className="hello-card">
                                    <img src="https://res.cloudinary.com/dtke9rvb6/image/upload/v1720946830/kovvur-icons/workers/Electricians.jpg" alt="Electricians" />
                                </div>
                                <div className="hello-card-info">
                                    <h3>Electricians</h3>
                                </div>
                            </div>
                            </Link>
                            <Link to="/Painters" className="hello-card-link">
                            <div className="hello-card-wrapper">
                                <div className="hello-card">
                                    <img src="https://res.cloudinary.com/dtke9rvb6/image/upload/v1723633933/kovvur-icons/workers/painters.jpg" alt="Painters" />
                                </div>
                                <div className="hello-card-info">
                                    <h3>Painters</h3>
                                </div>
                            </div>
                            </Link>
                            <Link to="/plumber" className="hello-card-link">
                            <div className="hello-card-wrapper">
                                <div className="hello-card">
                                    <img src="https://res.cloudinary.com/dtke9rvb6/image/upload/v1720946830/kovvur-icons/workers/Plumbers.jpg" alt="Plumbers" />
                                </div>
                                <div className="hello-card-info">
                                    <h3>Plumbers</h3>
                                </div>
                            </div>
                            </Link>
                            <Link to="/tailswork" className="hello-card-link">
                            <div className="hello-card-wrapper">
                                <div className="hello-card">
                                    <img src="https://res.cloudinary.com/dtke9rvb6/image/upload/v1720946830/kovvur-icons/workers/Tiles_workers.jpg" alt="TilesWorkers" />
                                </div>
                                <div className="hello-card-info">
                                    <h3>Tiles Works</h3>
                                </div>
                            </div>
                            </Link>
                        </section>
                    </div>
                </section>
            </main>
        </div>
    );
}

export default Workers;
