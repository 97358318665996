import React, { useState, useEffect } from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import ScrollToTop from './ScrollToTop'; // Import ScrollToTop
import Home from './pages/Home/Home';
import EventPlanner from './ServicePages/EventsPage/EventsList/EventPlanner';
import Events from './ServicePages/EventsPage/Events/Events';
import Photographylist from './ServicePages/PhotographyPage/Photography';
import Mechanicslist from './ServicePages/Mechanics/Mechanics'
import Weldingworkslist from './ServicePages/Weldingworks/Weldingworks';
import Vehicles from './ServicePages/TravelsPage/Vehicles/Vehicles';
import HealthRMPList from './ServicePages/HealthPage/HealthList/HealthRMPList';
import Health from './ServicePages/HealthPage/Health/Health';
import MilkdairyList from './ServicePages/Milkdairy/MilkdairyList';
import Workers from './ServicePages/WorkersPage/Workers/Workers';
import BeautyParlorList from './ServicePages/Beautyparlor/BeautyParlorList';
import PetshopList from './ServicePages/Petshop/PetshopList';
import ArchakuluList from './ServicePages/Archakulu/ArchakuluList';
import CCcameraList from './ServicePages/CCcamera/CCcameraList';
import DrivingSchoolList from './ServicePages/DrivingSchool/DrivingSchoolList';
import Accountant from './ServicePages/Accountant/Accountant';
import InteriorList from './ServicePages/Interior/InteriorList';
import InternetProviderList from './ServicePages/InternetProvider/InternetProviderList';
import TailoringList from './ServicePages/Tailoring/TailoringList';
import TuitionsList from './ServicePages/Tuitions/TuitionsList';
import Preloader from './component/Preloader/Preloader'; 
import JobsPage from './ServicePages/JobPage/JobPage';
import Prejoblist from './component/Jobslist/Prejobslist/Prejoblist';
import GovList from './component/Jobslist/Govjobslist/GovList';
import Autos from './ServicePages/TravelsPage/VehicleList/Autos';
import Car from './ServicePages/TravelsPage/VehicleList/Car';
import Van from './ServicePages/TravelsPage/VehicleList/Van';
import WheelLoaders from './ServicePages/TravelsPage/VehicleList/WheelLoaders';
import Lorry from './ServicePages/TravelsPage/VehicleList/Lorry';
import Bus from './ServicePages/TravelsPage/VehicleList/Bus';
import Eventdecro from './ServicePages/EventsPage/EventsList/Eventdecro';
import Eventtents from './ServicePages/EventsPage/EventsList/Eventtents';
import Sounds from './ServicePages/EventsPage/EventsList/Sounds';
import FoodCaterings from './ServicePages/EventsPage/EventsList/FoodCaterings';
import Functionhalls from './ServicePages/EventsPage/EventsList/Functionhalls';
import Lightdecro from './ServicePages/EventsPage/EventsList/Lightdecro';
import Hospitals from './ServicePages/HealthPage/HealthList/Hospitals';
import Dentalhospitals from './ServicePages/HealthPage/HealthList/Dentalhospitals';
import Ambulance from './ServicePages/HealthPage/HealthList/Ambulance';
import Bloodtest from './ServicePages/HealthPage/HealthList/Bloodtest';
import Homeopathy from './ServicePages/HealthPage/HealthList/Homeopathy';
import Acrepairs from './ServicePages/WorkersPage/WorkersList/Acrepairs';
import Ceiling from './ServicePages/WorkersPage/WorkersList/Ceiling';
import ConstructionWorkers from './ServicePages/WorkersPage/WorkersList/ConstructionWorkers';
import Electriton from './ServicePages/WorkersPage/WorkersList/Electriton';
import Plumber from './ServicePages/WorkersPage/WorkersList/Plumber';
import Painters from './ServicePages/WorkersPage/WorkersList/Painters';
import TailsWork from './ServicePages/WorkersPage/WorkersList/TailsWork';
import CloudinaryImage from './CloudinaryImage';
import Privacy from './Teams/Privacy'
import Termsandconditions from './Teams/Teamsandconditions'
import Sitemap from './Teams/Sitemap';


const App = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000); // Adjust the timeout as needed

    return () => clearTimeout(timer);
  }, []);

  return (
    <Router>
      <ScrollToTop /> {/* Add ScrollToTop here */}
      {isLoading ? (
        <Preloader />
      ) : (
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/vehicles" element={<Vehicles />} />
          <Route path="/events" element={<Events />} />
          <Route path="/health" element={<Health />} />
          <Route path="/photography" element={<Photographylist />} />
          <Route path="/milkdairylist" element={<MilkdairyList />} />
          <Route path="/Mechanicslist" element={<Mechanicslist />} />
          <Route path="/Weldingworkslist" element={<Weldingworkslist/>} />
          <Route path="/workers" element={<Workers />} />
          <Route path="/beautyparlorlist" element={<BeautyParlorList />} />
          <Route path="/petshoplist" element={<PetshopList />} />
          <Route path="/archakululist" element={<ArchakuluList />} />
          <Route path="/cccameralist" element={<CCcameraList />} />
          <Route path="/drivingschoollist" element={<DrivingSchoolList />} />
          <Route path="/Accountant" element={<Accountant />} />
          <Route path="/interiorlist" element={<InteriorList />} />
          <Route path="/internetproviderlist" element={<InternetProviderList />} />
          <Route path="/tailoringlist" element={<TailoringList />} />
          <Route path="/tuitionslist" element={<TuitionsList />} />
          <Route path="/jobspage" element={<JobsPage />} />
          <Route path="/prejoblist" element={<Prejoblist />} />
          <Route path="/govlist" element={<GovList />} />
          <Route path="/autosservice" element={<Autos />} />
          <Route path="/carservice" element={<Car />} />
          <Route path="/vanservice" element={<Van />} />
          <Route path="/wheelloadersservice" element={<WheelLoaders />} />
          <Route path="/lorryservice" element={<Lorry />} />
          <Route path="/busservice" element={<Bus />} />
          <Route path="/eventplanner" element={<EventPlanner />} />
          <Route path="/eventdecro" element={<Eventdecro />} />
          <Route path="/eventtents" element={<Eventtents />} />
          <Route path="/Sounds" element={<Sounds />} />
          <Route path="/foodcaterings" element={<FoodCaterings />} />
          <Route path="/functionhalls" element={<Functionhalls />} />
          <Route path="/lightdecro" element={<Lightdecro />} />
          <Route path="/healthrmplist" element={<HealthRMPList />} />
          <Route path="/hospitals" element={<Hospitals />} />
          <Route path="/dentalhospital" element={<Dentalhospitals />} />
          <Route path="/ambulance" element={<Ambulance />} />
          <Route path="/bloodtest" element={<Bloodtest />} />
          <Route path="/homeopathy" element={<Homeopathy />} />
          <Route path="/acrepairs" element={<Acrepairs />} />
          <Route path="/ceiling" element={<Ceiling />} />
          <Route path="/contructionworkers" element={<ConstructionWorkers />} />
          <Route path="/electrition" element={<Electriton />} />
          <Route path="/plumber" element={<Plumber />} />
          <Route path="/Painters" element={<Painters />} />
          <Route path="/tailswork" element={<TailsWork />} />
          <Route path="/cloudinary" element={<CloudinaryImage />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/termsandconditions" element={<Termsandconditions />} />
          <Route path="/sitemap" element={<Sitemap />} />
          
        </Routes>
      )}
    </Router>
  );
}

export default App;
