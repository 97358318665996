import React from 'react';
import { FaPhone, FaEnvelope, FaInstagram, FaFacebook, FaYoutube } from 'react-icons/fa';
import { FaThreads } from "react-icons/fa6";
import './Footer.css';
import { Link } from 'react-router-dom';
import FooterReadMore from './FooterReadMore';


const UniqueFooter = () => {
  return (
    <div>

      <div className="footer-top" style={{ backgroundImage: "url('https://res.cloudinary.com/dtke9rvb6/image/upload/v1721150663/kovvur-icons/footer/footer.jpg')" }}>

        <section id="unique-footer">

          <div className="unique-main-footer">
            <div className="unique-logoinfo" data-aos="fade-up">

              <h2 className="unique-title">Mana Kovvur</h2>
              <p className="unique-subtitle">Explore all the different services in Kovvur stay updated with the latest job opportunities, and access live news channels. Your one-stop destination for all village updates and essential information.</p>

            </div>
            <div className="unique-com" data-aos="fade-up">
              <h1 className="unique-about-title">Explore Mana Kovvur</h1>
              <ul className="unique-about-list">
                <li className="unique-about-item">
                  <Link to="/sitemap" className="unique-about-link">Sitemap</Link>
                </li>
                <li className="unique-about-item">
                  <Link to="/termsandconditions" className="unique-about-link">Terms & Conditions</Link>
                </li>
                <li className="unique-about-item">
                  <Link to="/privacy" className="unique-about-link">Privacy Policy</Link>
                </li>
              </ul>
            </div>
            <div className="unique-contact-details">
              <h1 className="unique-contact-title">Contact Us</h1>
              <ul className="unique-contact-list">
                <li className="unique-contact-item ">
                  <FaPhone /><a href="tel:+919491344745" className="unique-contact-link">+91 8985443006</a>
                </li>
                <li className="unique-contact-item ">
                  <FaEnvelope /><a href="mailto:yourmail@gmail.com" className="unique-contact-link">manakovvur@gmail.com</a>
                </li>
              </ul>
            </div>

            <div className="unique-info" data-aos="fade-up">
              <h1 className="unique-social-title">Social Media</h1>
              <div className="unique-sociallogos">
                <div className="unique-logobox">
                  <a href="https://www.instagram.com/mana_kovvur_official/" target="_blank" rel="noopener noreferrer" className="unique-social-link"><FaInstagram /></a>
                  
                  <a href="https://www.facebook.com/profile.php?id=61563746487029" target="_blank" rel="noopener noreferrer" className="unique-social-link"><FaFacebook /></a>
                  <a href="https://www.threads.net/@mana_kovvur_official" target="_blank" rel="noopener noreferrer" className="unique-social-link"><FaThreads /></a>
                  <a href="#" target="_blank" rel="noopener noreferrer" className="unique-social-link"><FaYoutube /></a>
                </div>

              </div>
            </div>
          </div>

        </section>
      </div>
      <FooterReadMore />
      <footer className="unique-footer-text">Thank you for visiting us!</footer>
    </div>

  );
}

export default UniqueFooter;
