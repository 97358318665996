import React from 'react';
import './Teams.css';
import { FaArrowLeft } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Privacy = () => {
    return (
        <>
            <div className="Policy-Style-header">
                <Link to="/">
                    <button className="ListStyle-back-button"><FaArrowLeft /></button>
                </Link>
                <h1 className="Termsandconditions">PRIVACY POLICY</h1>
            </div>
                <section className="terms-content">
                    <h2 className="terms-title">Introduction</h2>
                    <p className="terms-paragraph">
                        Welcome to Mana Kovvur. We are committed to protecting your privacy.
                        This Privacy Policy outlines how we collect, use, and safeguard your
                        information when you use our website, which provides various services
                        available in our village including travel, health, beauty parlours, and
                        workers.
                    </p>
                    <h2 className="terms-title">Information We Collect</h2>
                    <p className="terms-paragraph">
                        We may collect personal information such as your name, email address,
                        phone number, and other details when you:
                    </p>
                    <ul className='terms-ul'>
                        <li className="terms-paragraph">Fill out forms on our website</li>
                        <li className="terms-paragraph">
                            Contact us via email or through other communication channels
                        </li>
                        <li className="terms-paragraph">Use our services or make inquiries</li>
                    </ul>
                    <h2 className="terms-title">How We Use Your Information</h2>
                    <p className="terms-paragraph">We use your information to:</p>
                    <ul className='terms-ul'>
                        <li className="terms-paragraph">Provide and manage our services</li>
                        <li className="terms-paragraph">
                            Respond to your inquiries and provide customer support
                        </li>
                        <li className="terms-paragraph">
                            Send you updates about our services and special offers
                        </li>
                        <li className="terms-paragraph">Improve our website and services</li>
                    </ul>
                    <h2 className="terms-title">Data Protection</h2>
                    <p className="terms-paragraph">
                        We implement security measures to protect your information from
                        unauthorized access, alteration, disclosure, or destruction. However, no
                        method of transmission over the Internet or method of electronic storage
                        is 100% secure.
                    </p>
                    <h2 className="terms-title">Third-Party Services</h2>
                    <p className="terms-paragraph">
                        We do not sell, trade, or otherwise transfer your personal information
                        to outside parties. We may use third-party service providers to help us
                        operate our website or conduct our business, but they are obligated to
                        keep your information confidential.
                    </p>
                    <h2 className="terms-title">Your Rights</h2>
                    <p className="terms-paragraph">
                        You have the right to access, update, or delete your personal
                        information. If you would like to exercise these rights, please contact
                        us using the information provided below.
                    </p>
                    <h2 className="terms-title">Contact Us</h2>
                    <p className="terms-paragraph">
                        If you have any questions or concerns about this Privacy Policy, please
                        contact us at:
                    </p>
                    <p className="terms-paragraph">
                        Email:{" "}
                        <a className="terms-paragraph" href="mailto:manakovvur@gmail.com">
                            manakovvur@gmail.com
                        </a>
                    </p>
                    <h2 className="terms-title">Changes to This Privacy Policy</h2>
                    <p className="terms-paragraph">
                        We may update this Privacy Policy from time to time. We will notify you
                        of any changes by posting the new Privacy Policy on our website. You are
                        advised to review this Privacy Policy periodically for any changes.
                    </p>
                    <p className="terms-paragraph"><strong>Effective Date: July 27, 2024</strong></p>
                </section>
            
            <footer>
                <p>© 2024 Mana Kovvur. All rights reserved.</p>
            </footer>
        </>


    );
}

export default Privacy;
