import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './GovList.css';
import GovCard from './GovCard';
import { Link } from 'react-router-dom';
import { FaArrowLeft, FaSearch, FaTimes } from 'react-icons/fa';
import './../../../Stylings/MainCss/ListStyle.css';

const GovList = () => {
    const [govData, setGovData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://raw.githubusercontent.com/manakovvur/Testingone/main/govdata.json');
                setGovData(response.data);
                setFilteredData(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const handleSearch = (event) => {
        const term = event.target.value.toLowerCase();
        setSearchTerm(term);
        setFilteredData(govData.filter(gov => gov.title.toLowerCase().includes(term)));
    };

    return (
        <div>
            <div className="ListStyle-header">
                <Link to="/JobSPage">
                    <button className="ListStyle-back-button">
                        <FaArrowLeft />
                    </button>
                </Link>
                <h1>GOVT JOBS</h1>
            </div>
            
            <div className="ListStyle-search-bar-container">
                <div className="ListStyle-search-bar-wrapper">
                    <FaSearch className="ListStyle-search-icon" />
                    <input
                        type="text"
                        placeholder="Search for Government jobs..."
                        value={searchTerm}
                        onChange={handleSearch}
                        className="ListStyle-search-bar"
                    />
                    {searchTerm && (
                        <FaTimes 
                            className="ListStyle-clear-icon" 
                            onClick={() => {
                                setSearchTerm(''); 
                                setFilteredData(govData); // Reset filteredData to the original dataset
                            }} 
                        />
                    )}
                </div>
            </div>

            {loading ? (
                <div className="ListStyle-loading">
                    <div className="loader"></div>
                    <span>Loading...</span>
                </div>
            ) : (
                <div className="gov-container fadeIn">
                    {filteredData.length > 0 ? (
                        filteredData.map((gov, index) => (
                            <GovCard
                                key={index}
                                imgSrc={gov.imgSrc}
                                title={gov.title}
                                description={gov.description}
                                pdfLink={gov.pdfLink}
                                applyLink={gov.applyLink}
                            />
                        ))
                    ) : (
                        <div className="ListStyle-no-data">
                            <img
                                src="https://res.cloudinary.com/dtke9rvb6/image/upload/v1723191462/kovvur-icons/no%20data%20found/no-data-found.png"
                                alt="No data found"
                                className="ListStyle-no-data"
                                loading="lazy"
                            />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default GovList;
