import React from 'react';
import Navbar from '../../component/Navbar/Navbar';
import CaruoselMain from '../../component/Caruosel/CaruoselMain';
import Services from '../../component/Service/Services';
import Ads from '../../component/Ads/Ads';
import Jobupdates from '../../component/Jobupdates/Jobupdates';
import LiveNews from '../../component/LiveNews/LiveNews';
import Footer from '../../component/Footer/Footer';
import YouTubeVideos from '../../component/YoutubeAds/YouTubeVideos'

import './Home.css';

const Home = () => {
  return (
    <div className="home-container">
      <Navbar/>
      <CaruoselMain/>
      <Services/>
      <Ads/>
      <Jobupdates/>
      <YouTubeVideos />
      <LiveNews/>
      <Footer/>
    </div>
  );
}

export default Home;
