import React from 'react'
import CustomSlider from './CustomSlider'
import ImageCaruosel from './ImageCaruosel'
const CaruoselMain = () => {
  return (
    <div>
      <div
      className="caruoselcontainer">
        <CustomSlider>
        {ImageCaruosel.map((image, index) => {
          return <img key={index} src={image.imgURL} alt={image.imgAlt} />;
        })}
      </CustomSlider>

      </div>
      
    </div>
  )
}

export default CaruoselMain
