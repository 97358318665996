import React from 'react';
import './Teams.css';
import { FaArrowLeft } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import './Teams.css';

const Termsandconditions = () => {
    return (
        <>
        <div className="Terms-Style-header">
                <Link to="/">
                    <button className="ListStyle-back-button"><FaArrowLeft /></button>
                </Link>
                <h1 className="Termsandconditions">TERMS AND CONDITIONS</h1>
            </div>
            <main className="terms-content">
                <section className="terms-section">
                    <h2 className="terms-title">Terms and Conditions</h2>
                    <p className="terms-paragraph">
                        Welcome to Mana Kovvur! These Terms and Conditions outline the rules and
                        guidelines for using our website located at{" "}
                        <a className="terms-paragraph" href="/">
                            www.manakovvur.com
                        </a>
                        . By accessing and using our site, you agree to comply with and be bound
                        by these terms. If you do not agree with any part of these terms, you
                        should discontinue your use of the site immediately. This includes, but
                        is not limited to, any interaction with our services, such as travel
                        arrangements, health services, beauty parlour bookings, or local worker
                        services. Your continued use of the site constitutes your acceptance of
                        these terms, which may be updated periodically. It is your
                        responsibility to review these terms regularly to stay informed of any
                        changes that might affect you.
                    </p>
                    <h3 className="terms-subtitle">1. Introduction</h3>
                    <p className="terms-paragraph">
                        By accessing or using the Mana Kovvur website, you agree to the terms
                        and conditions set forth. These terms govern your relationship with Mana
                        Kovvur and your use of our site and services. If you do not accept these
                        terms, you should not use the site. Continued use of the site implies
                        acceptance of these terms and conditions. We reserve the right to change
                        these terms at our discretion, and any modifications will be effective
                        immediately upon posting on this page. It is your responsibility to
                        review the terms periodically to ensure that you are aware of any
                        updates. By using the site after changes have been made, you accept and
                        agree to the revised terms.
                    </p>
                    <h3 className="terms-subtitle">2. Services Provided</h3>
                    <p className="terms-paragraph">
                        Mana Kovvur offers a diverse range of services including travel
                        arrangements, health services, beauty parlour appointments, and local
                        worker services. Each of these services is provided by different vendors
                        or service providers. Availability may vary based on the specific
                        service and local conditions. The terms for each type of service may
                        differ, and it is essential to review the specific terms applicable to
                        the service you are interested in. While Mana Kovvur strives to ensure
                        the accuracy and quality of the services listed, we do not guarantee the
                        availability or suitability of any service. It is advisable to contact
                        the service provider directly for detailed information and specific
                        terms related to their offerings.
                    </p>
                    <h3 className="terms-subtitle">3. Service Agreements</h3>
                    <p className="terms-paragraph">
                        When using services provided through Mana Kovvur, you are entering into
                        a contractual agreement with the individual service providers, not with
                        Mana Kovvur directly. Therefore, you are bound by the terms and
                        conditions set by these service providers. Mana Kovvur acts as an
                        intermediary to connect users with service providers and is not
                        responsible for any disputes or issues arising from the use of these
                        services. It is crucial to review the terms of service of each provider
                        to understand their specific policies, cancellation terms, and other
                        relevant details. Mana Kovvur is not liable for any actions or omissions
                        by the service providers or for any consequences arising from such
                        interactions.
                    </p>
                    <h3 className="terms-subtitle">4. User Responsibilities</h3>
                    <p className="terms-paragraph">
                        As a user of the Mana Kovvur website and its services, you agree to use
                        the site in a lawful and responsible manner. This includes refraining
                        from any activities that could disrupt or interfere with the
                        functionality of the site, such as hacking or attempting to gain
                        unauthorized access. Additionally, you must not use the site to engage
                        in fraudulent activities, harassment, or any other behavior that could
                        be deemed illegal or harmful to others. Any violation of these
                        responsibilities may result in suspension or termination of your access
                        to the site and services. Mana Kovvur reserves the right to take
                        appropriate legal action in cases of misuse or abuse of the site.
                    </p>
                    <h3 className="terms-subtitle">5. Intellectual Property</h3>
                    <p className="terms-paragraph">
                        The content available on the Mana Kovvur website, including text,
                        graphics, logos, and images, is the intellectual property of Mana Kovvur
                        or its content suppliers. This content is protected by copyright and
                        trademark laws. You may not reproduce, distribute, or otherwise use any
                        of the site’s content without explicit permission from Mana Kovvur.
                        Unauthorized use of the content may result in legal action. All
                        trademarks, service marks, and trade names are owned by their respective
                        owners. We ask that you respect the intellectual property rights of Mana
                        Kovvur and others when using our site and services.
                    </p>
                    <h3 className="terms-subtitle">6. Limitation of Liability</h3>
                    <p className="terms-paragraph">
                        Mana Kovvur strives to provide accurate and reliable information and
                        services. However, we do not guarantee the accuracy, completeness, or
                        timeliness of the information provided on the site. We are not liable
                        for any damages, losses, or issues that may arise from the use of the
                        site or its services, including but not limited to direct, indirect,
                        incidental, or consequential damages. Our liability is limited to the
                        fullest extent permitted by law. Users acknowledge that they use the
                        site and its services at their own risk and that Mana Kovvur is not
                        responsible for any errors or omissions in the content or for any harm
                        resulting from such use.
                    </p>
                    <h3 className="terms-subtitle">7. Changes to Terms</h3>
                    <p className="terms-paragraph">
                        Mana Kovvur reserves the right to modify these Terms and Conditions at
                        any time. Any changes made will be posted on this page and will take
                        effect immediately upon posting. It is your responsibility to review
                        these terms periodically to ensure that you are aware of any updates or
                        modifications. Continued use of the site after any changes have been
                        made constitutes acceptance of the revised terms. We encourage you to
                        check this page regularly to stay informed about our current terms and
                        conditions, as they may affect your rights and responsibilities as a
                        user of the site.
                    </p>
                    <h3 className="terms-subtitle">8. Contact Information</h3>
                    <p className="terms-paragraph">
                        If you have any questions or concerns regarding these Terms and
                        Conditions, please do not hesitate to contact us. You can reach us via
                        email at{" "}
                        <a className="terms-paragraph" href="mailto:manakovvur@gmail.com">
                            manakovvur@gmail.com
                        </a>
                        . Our team is available to assist you with any inquiries or issues you
                        may have. We value your feedback and are committed to ensuring a
                        positive experience while using our site and services. Please feel free
                        to get in touch with us if you need further clarification or assistance
                        regarding any aspect of these terms.
                    </p>
                    <p className="terms-last-updated">Last updated: July 27, 2024</p>
                </section>
            </main>
            <footer className="terms-footer">
                <p>© 2024 Mana Kovvur. All Rights Reserved.</p>
            </footer>
        </>


    );
}

export default Termsandconditions;