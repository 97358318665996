import React from 'react';
// import { useNavigate } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import './../../../Stylings/MainCss/Style.css';

const Health = () => {
    
    return (
        <div>
            <div className="hello-header">
            <Link to="/">
                <button className="hello-back-button" >
                    <FaArrowLeft />
                </button>
                </Link>

                <h1>HEALTH</h1>

            </div>
            <main className="hello-main-content">
                <section className="hello-travels-container">
                    <div className="hello-travels">
                        <section className="hello-cards">
                        <Link to="/HealthRMPList" className="hello-card-link">
                            <div className="hello-card-wrapper">
                                
                                <div className="hello-card">
                                    <img src="https://res.cloudinary.com/dtke9rvb6/image/upload/v1720935747/kovvur-icons/Health/rmp.jpg" alt="rmp" />
                                </div>
                                <div className="hello-card-info">
                                    <h3>RMP Doctor</h3>
                                </div>
                            </div>
                            </Link>
                            <Link to="/dentalhospital" className="hello-card-link">
                            <div className="hello-card-wrapper">
                                <div className="hello-card">
                                    <img src="https://res.cloudinary.com/dtke9rvb6/image/upload/v1720935746/kovvur-icons/Health/Dental_Hospitals.jpg" alt="Dental" />
                                </div>
                                <div className="hello-card-info">
                                    <h3>Dental <br />Hospitals</h3>
                                </div>
                            </div>
                            </Link>
                            <Link to="/bloodtest" className="hello-card-link">
                            <div className="hello-card-wrapper">
                                <div className="hello-card">
                                    <img src="https://res.cloudinary.com/dtke9rvb6/image/upload/v1720935746/kovvur-icons/Health/Blood_Test_Centers.jpg" alt="BloodTestCenters" />
                                </div>
                                <div className="hello-card-info">
                                    <h3>Blood Test <br />Centers</h3>
                                </div>
                            </div>
                            </Link>
                            <Link to="/hospitals" className="hello-card-link">
                            <div className="hello-card-wrapper">
                                <div className="hello-card">
                                    <img src="https://res.cloudinary.com/dtke9rvb6/image/upload/v1720935747/kovvur-icons/Health/Hospitals.jpg" alt="Hospitals" />
                                </div>
                                <div className="hello-card-info">
                                    <h3>Hospitals</h3>
                                </div>
                            </div>
                            </Link>
                            <Link to="/homeopathy" className="hello-card-link">
                            <div className="hello-card-wrapper">
                                <div className="hello-card">
                                    <img src="https://res.cloudinary.com/dtke9rvb6/image/upload/v1720935746/kovvur-icons/Health/Homeopathy.jpg" alt="Homeopathy" />
                                </div>
                                <div className="hello-card-info">
                                    <h3>Homeopathy</h3>
                                </div>
                            </div>
                            </Link>
                            <Link to="/ambulance" className="hello-card-link">
                            <div className="hello-card-wrapper">
                                <div className="hello-card">
                                    <img src="https://res.cloudinary.com/dtke9rvb6/image/upload/v1720935747/kovvur-icons/Health/Ambulance.jpg" alt="Ambulance" />
                                </div>
                                <div className="hello-card-info">
                                    <h3>Ambulance</h3>
                                </div>
                            </div>
                            </Link>
                        </section>
                    </div>
                </section>
            </main>
        </div>
    );
}

export default Health;
