// Sitemap.js
import React from 'react';
import { Link } from 'react-router-dom';
import './Sitemap.css'; 
import { FaArrowLeft } from 'react-icons/fa';


const Sitemap = () => {
  return (
    <>
    <div className="Policy-Style-header">
                <Link to="/">
                    <button className="ListStyle-back-button"><FaArrowLeft /></button>
                </Link>
                <h1 className="Termsandconditions">SITEMAP</h1>
            </div>
    <div className="sitemap-container">
      <h1>Sitemap</h1>
      <ul>
        <li className='homesitename'><Link to="/">Home</Link></li>
        <li>
          
        <h3 className='sub-title'>Vehicles</h3>
          <ul>
            <li><Link to="/autosservice">Auto Services</Link></li>
            <li><Link to="/carservice">Car Services</Link></li>
            <li><Link to="/vanservice">Van Services</Link></li>
            <li><Link to="/wheelloadersservice">Wheel Loaders</Link></li>
            <li><Link to="/lorryservice">Lorry Services</Link></li>
            <li><Link to="/busservice">Bus Services</Link></li>
          </ul>
        </li>
        <li>
         
          <h3 className='sub-title'>Events</h3>
          <ul>
            <li><Link to="/eventplanner">Event Planner</Link></li>
            <li><Link to="/eventdecro">Event Decoration</Link></li>
            <li><Link to="/eventtents">Event Tents</Link></li>
            <li><Link to="/foodcaterings">Food Caterings</Link></li>
            <li><Link to="/functionhalls">Function Halls</Link></li>
            <li><Link to="/lightdecro">Light Decoration</Link></li>
          </ul>
        </li>
        <li>
          
          <h3 className='sub-title'>Health</h3>
          <ul>
            <li><Link to="/healthrmplist">Health RMP</Link></li>
            <li><Link to="/hospitals">Hospitals</Link></li>
            <li><Link to="/dentalhospital">Dental Hospitals</Link></li>
            <li><Link to="/ambulance">Ambulance</Link></li>
            <li><Link to="/bloodtest">Blood Test</Link></li>
            <li><Link to="/homeopathy">Homeopathy</Link></li>
          </ul>
        </li>
        
        <li>
          
          <h3 className='sub-title'>Workers</h3>
          <ul>
            <li><Link to="/acrepairs">AC Repairs</Link></li>
            <li><Link to="/ceiling">Ceiling</Link></li>
            <li><Link to="/contructionworkers">Construction Workers</Link></li>
            <li><Link to="/electrition">Electrician</Link></li>
            <li><Link to="/plumber">Plumber</Link></li>
            <li><Link to="/tailswork">Tails Work</Link></li>
          </ul>
        </li>
        <li><Link to="/beautyparlorlist">Beauty Parlors</Link></li>
        <li><Link to="/photography">Photography</Link></li>
        <li><Link to="/milkdairylist">Milk Dairy</Link></li>
        <li><Link to="/petshoplist">Pet Shops</Link></li>
        <li><Link to="/archakululist">Archakulu</Link></li>
        <li><Link to="/cccameralist">CCTV Camera</Link></li>
        <li><Link to="/drivingschoollist">Driving Schools</Link></li>
        <li><Link to="/housewarming">House Warming</Link></li>
        <li><Link to="/interiorlist">Interior</Link></li>
        <li><Link to="/internetproviderlist">Internet Providers</Link></li>
        <li><Link to="/tailoringlist">Tailoring</Link></li>
        <li><Link to="/tuitionslist">Tuitions</Link></li>
        <li>

          
          <h3 className='sub-title'>Jobs</h3>
          <ul>
            <li><Link to="/jobspage">Jobs</Link></li>
            <li><Link to="/prejoblist">Pre Jobs</Link></li>
            <li><Link to="/govlist">Government Jobs</Link></li>
          </ul>
        </li>
      </ul>
    </div>
    </>
  );
}

export default Sitemap;
