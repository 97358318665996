import React, { useState } from 'react';

const FooterReadMore = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <footer>
      <p className='footerreadmore'>
        {isExpanded
          ? "Disclaimer: The information provided on this website is for general information purposes only. We strive to keep the information up-to-date and accurate, but we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability of the services listed. Any reliance you place on such information is therefore strictly at your own risk."
          : "Disclaimer: The information provided on this website is for general information purposes only. We strive to keep the information up-to-date and accurate, but we make..."}
        <span onClick={toggleReadMore} style={{ cursor: 'pointer', color: '#fff', fontSize:'13px' }}>
          {isExpanded ? ' Read Less' : ' Read More'}
        </span>
      </p>
    </footer>
  );
};

export default FooterReadMore;
