import React, { useState, useEffect } from 'react';
import './../../../Stylings/MainCss/ListStyle.css'
import axios from 'axios';
import Footer from '../../../component/Footer/Footer'
import { FaArrowLeft, FaPhone, FaSearch, FaTimes, FaMapMarkerAlt, FaWhatsapp, FaInstagram,FaFilePdf } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Ads from '../../../component/Ads/Ads'

const Plumber = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const [expandedItems, setExpandedItems] = useState({});
    const [shuffledData, setShuffledData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://raw.githubusercontent.com/manakovvur/mana-kovvur-data/master/Workers/Plumbers-data.json');
                setShuffledData(shuffleArray(response.data.PlumbersData));
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const shuffleArray = (array) => {
        let shuffledArray = array.slice();
        for (let i = shuffledArray.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
        }
        return shuffledArray;
    };

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
    };

    const toggleExpand = (id) => {
        setExpandedItems((prevExpandedItems) => ({
            ...prevExpandedItems,
            [id]: !prevExpandedItems[id]
        }));
    };

    const filteredData = shuffledData.filter(
        item =>
            item.location.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.title.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div>
            <div className="ListStyle-header">
            <Link to="/Workers">
                    <button className="ListStyle-back-button"><FaArrowLeft /></button>
                </Link>
                <h1>PLUMBERS</h1>
            </div>

            <div className="ListStyle-search-bar-container">
                <div className="ListStyle-search-bar-wrapper">
                    <FaSearch className="ListStyle-search-icon" />
                    <input
                        type="text"
                        placeholder="Search..."
                        value={searchQuery}
                        onChange={handleSearch}
                        className="ListStyle-search-bar"
                    />
                    {searchQuery && (
                        <FaTimes className="ListStyle-clear-icon" onClick={() => setSearchQuery('')} />
                    )}
                </div>
            </div>

            {loading ? (
                <div className="ListStyle-loading">
                    <span className="loader"></span>
                    <p>Loading...</p>
                </div>
            ) : (
                <div className="ListStyle-grid">
                    {filteredData.length > 0 ? (
                        filteredData.map((item) => (
                            <div key={item.id} className="ListStyle-container fadeIn">
                                <div className="ListStyle-img-main">
                                    <img src={item.imgSrc} alt={item.title} className="ListStyle-photo" />
                                    <div className="ListStyle-details">
                                        <h2 className="ListStyle-title">{item.title}</h2>
                                        <p>{item.location}</p>
                                    </div>
                                </div>
                                <hr />
                                <div className="ListStyle-button-group">
                                    <button
                                        className="ListStyle-see-more-button"
                                        onClick={() => toggleExpand(item.id)}
                                    >
                                        {expandedItems[item.id] ? 'See Less' : 'See More'}
                                    </button>
                                    <a href={`tel:${item.phoneNumber}`} className="ListStyle-call-button">
                                        <FaPhone /> Call Now
                                    </a>
                                </div>
                                {expandedItems[item.id] && (
                                    <div>
                                        <h3 className="ListStyle-services-heading">SERVICES</h3>
                                        <ul className="ListStyle-services">
                                            {item.services.map((service, index) => (
                                                <li key={index}>{service}</li>
                                            ))}
                                        </ul>
                                        <div className="ListStyle-social-buttons">
                                            {item.socialLinks?.instagram && (
                                                <a href={item.socialLinks.instagram} className="ListStyle-social-button" target="_blank" rel="noopener noreferrer">
                                                    <FaInstagram />
                                                </a>
                                            )}
                                            {item.socialLinks?.whatsapp && (
                                                <a href={item.socialLinks.whatsapp} className="ListStyle-social-button" target="_blank" rel="noopener noreferrer">
                                                    <FaWhatsapp />
                                                </a>
                                            )}
                                            {item.socialLinks?.maps && (
                                                <a href={item.socialLinks.maps} className="ListStyle-social-button" target="_blank" rel="noopener noreferrer">
                                                    <FaMapMarkerAlt />
                                                </a>
                                            )}
                                            {item.socialLinks?.phonecall && (
                                                <a href={`tel:${item.socialLinks.phonecall}`} className="ListStyle-social-button">
                                                    <FaPhone />
                                                </a>
                                            )}
                                            {item.socialLinks?.pdf && (
                                                <a href={item.socialLinks.pdf} className="ListStyle-social-button" target="_blank" rel="noopener noreferrer">
                                                    <FaFilePdf />
                                                </a>
                                            )}
                                            {item.socialLinks?.pdf && (
                                               <a href={item.socialLinks.pdf} className="ListStyle-social-button" target="_blank" rel="noopener noreferrer">
                                                   <FaFilePdf />
                                               </a>
                                           )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        ))
                    ) : (
                        <div className="ListStyle-no-data">
                            <p>No data found</p>
                        </div>
                    )}
                </div>
            )}
             <Ads/>
            <Footer/>
        </div>
    );
};

export default Plumber;
