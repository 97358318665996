import React from 'react';
// import { useNavigate } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import './../../../Stylings/MainCss/Style.css';

const Events = () => {
    
    return (
        <div>
            <div className="hello-header">
            <Link to="/">
                <button className="hello-back-button" >
                    <FaArrowLeft />
                </button>
                </Link>

                <h1>EVENTS</h1>

            </div>
            <main className="hello-main-content">
                <section className="hello-travels-container">
                    <div className="hello-travels">
                        <section className="hello-cards">
                        <Link to="/EventPlanner" className="hello-card-link">
                            <div className="hello-card-wrapper">
                                
                                <div className="hello-card">
                                    <img src="https://res.cloudinary.com/dtke9rvb6/image/upload/v1720961994/kovvur-icons/Event_icons/Event%20Planners.jpg" alt="planner" />
                                </div>
                                <div className="hello-card-info">
                                    <h3>Event Planners</h3>
                                </div>
                            </div>
                            </Link>
                            <Link to="/foodcaterings" className="hello-card-link">
                            <div className="hello-card-wrapper">
                                <div className="hello-card">
                                    <img src="https://res.cloudinary.com/dtke9rvb6/image/upload/v1720961994/kovvur-icons/Event_icons/Food%20Catering.jpg" alt="food" />
                                </div>
                                <div className="hello-card-info">
                                    <h3>Food Caterings</h3>
                                </div>
                            </div>
                            </Link>
                            <Link to="/Sounds" className="hello-card-link">
                            <div className="hello-card-wrapper">
                                <div className="hello-card">
                                    <img src="https://res.cloudinary.com/dtke9rvb6/image/upload/v1725275583/kovvur-icons/Event_icons/sounds_xnpton.jpg" alt="tents" />
                                </div>
                                <div className="hello-card-info">
                                    <h3>Sounds</h3>
                                </div>
                            </div>
                            </Link>
                            
                            <Link to="/lightdecro" className="hello-card-link">
                            <div className="hello-card-wrapper">
                                <div className="hello-card">
                                    <img src="https://res.cloudinary.com/dtke9rvb6/image/upload/v1720961995/kovvur-icons/Event_icons/Light%20Decorations.jpg" alt="lights" />
                                </div>
                                <div className="hello-card-info">
                                    <h3>Light Decorations</h3>
                                </div>
                            </div>
                            </Link>
                            <Link to="/eventdecro" className="hello-card-link">
                            <div className="hello-card-wrapper">
                                <div className="hello-card">
                                    <img src="https://res.cloudinary.com/dtke9rvb6/image/upload/v1720961994/kovvur-icons/Event_icons/Decorations.jpg" alt="decorations" />
                                </div>
                                <div className="hello-card-info">
                                    <h3>Decorations</h3>
                                </div>
                            </div>
                            </Link>
                            <Link to="/functionhalls" className="hello-card-link">
                            <div className="hello-card-wrapper">
                                <div className="hello-card">
                                    <img src="https://res.cloudinary.com/dtke9rvb6/image/upload/v1720961994/kovvur-icons/Event_icons/Function%20Halls.jpg" alt="halls" />
                                </div>
                                <div className="hello-card-info">
                                    <h3>Function Halls</h3>
                                </div>
                            </div>
                            </Link>
                            <Link to="/eventtents" className="hello-card-link">
                            <div className="hello-card-wrapper">
                                <div className="hello-card">
                                    <img src="https://res.cloudinary.com/dtke9rvb6/image/upload/v1720961994/kovvur-icons/Event_icons/Event%20Tents.jpg" alt="tents" />
                                </div>
                                <div className="hello-card-info">
                                    <h3>Event Tents</h3>
                                </div>
                            </div>
                            </Link>
                        </section>
                    </div>
                </section>
            </main>
        </div>
    );
}

export default Events;
