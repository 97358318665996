const ImageCaruosel = [
    {
      imgURL:
        "https://res.cloudinary.com/dtke9rvb6/image/upload/v1720266462/kovvur-icons/carosal/carosal_11zon_vkr9j0.jpg",
      imgAlt: "img-1"
    },
    {
      imgURL:
        "https://res.cloudinary.com/dtke9rvb6/image/upload/v1720266810/kovvur-icons/carosal/carosal2.jpg",
      imgAlt: "img-2"
    },
    {
      imgURL:
        "https://res.cloudinary.com/dtke9rvb6/image/upload/v1720528243/kovvur-icons/carosal/carosal_2__11zon_fwyoau.jpg",
      imgAlt: "img-3"
    },
    {
      imgURL:
        "https://res.cloudinary.com/dtke9rvb6/image/upload/v1721214252/kovvur-icons/carosal/carosal_11zon_iqtwag.jpg",
      imgAlt: "img-4"
    }
  ];
  
  export default ImageCaruosel;
  